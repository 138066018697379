import axios from "axios";
import { API_BASE } from "../spectory-config";


const updateCart = async (data) => {
    
    return axios.post(API_BASE + '/cart', data).then(res => {
        return res.data;
    }).catch(err => {
        return err;
    })

}

export default updateCart;