import { Link, navigate, withPrefix } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import isLoggedIn, { getUserToken } from "../../../services/auth"
import axios from "axios";
import { API_BASE } from "../../../spectory-config";
import Layout from "./Layout"
import Button from "../../../components/elements/Button"
import TextInput from "../../../components/elements/Inputs/TextInput";
import Checkbox from "../../../components/elements/Inputs/Checkbox";
import LoadingSpinner from "../../../components/elements/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { changeCheckoutStep, closeNotification, setNotification, storeFormDataAction } from "../../../store/actions";

const Billing = (props) => {

    const { register, handleSubmit, reset, watch } = useForm();
    const [address, setAddress] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [checkValidity, setCheckValidity] = useState(false);

    const [formValids, setFormValids] = useState({
        first_name: false,
        last_name: false,
        address_1: false,
        postcode: false,
        city: false,
        email: false
    });

    const dispatch = useDispatch();

    const billingData = useSelector(state => state?.checkout.billing)
    const registerData = useSelector(state => state?.checkout.register)

    dispatch(changeCheckoutStep({ currentStep: 'billing' }));

    const nextPage = () => {

        setCheckValidity(true);
        setTimeout(() => {
            setCheckValidity(false);
        }, 20)

        let isValid = true;

        Object.keys(formValids).forEach(key => {
            if (formValids[key] === false) isValid = false;
        })

        if (!isValid) {
            dispatch(setNotification({
                show: true,
                type: "error",
                title: "Bitte füllen Sie alle rot markierten Felder aus.",
            }));

            return false;
        }

        dispatch(closeNotification());

        navigate(withPrefix('/kasse/shipping'))
    }

    const setValid = (name, valid) => {

        console.log("SETVALID", name, valid);

        setFormValids(prev => {
            return {
                ...prev,
                [name]: valid
            }
        });

    }

    watch((data, { name, type }) => {

        if (name !== 'password') {
            let _data = billingData;
            _data[name] = data[name];

            dispatch(storeFormDataAction({ billing: _data }))

            console.log("WATCH", _data, name, type)
        }

        if (registerData.withRegister && (name === 'password' || name === 'email')) {

            let _registerData = registerData;
            _registerData[name] = data[name];

            dispatch(storeFormDataAction({ register: _registerData }))

            console.log("WATCH", _registerData, name, type)
        }

    })

    useEffect(() => {

        isLoggedIn().then(res => {

            setLoggedIn(res);

            if (res) {

                setLoading(true);

                var config = {
                    method: 'post',
                    url: `${API_BASE}/customer/addresses/get`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        token: getUserToken()
                    }
                };

                axios(config)
                    .then(function (response) {

                        if (response.data.c === 200) {
                            console.log("response", response.data.data.billing, billingData);

                            if (billingData.first_name === '') {
                                dispatch(storeFormDataAction({ billing: response.data.data.billing }));
                                setAddress(response.data.data['billing']);
                            } else {
                                setAddress(billingData);
                            }

                        } else {
                            setAddress(billingData);
                        }

                        setLoading(false);
                    })
                    .catch(function (error) {
                        console.log("response err", error)
                        setLoading(false);
                    });

            } else {
                setAddress(billingData);
                setLoading(false)
            }
        })

    }, []);


    return (
        <Layout path={props.path}>

            <h4>Rechnungsadresse</h4>
            <form>
                <div className="container fluid" style={{ padding: 0 }}>
                    <div className="col6">
                        <div>
                            <TextInput
                                required
                                initValue={address['first_name']}
                                type="text"
                                label="Vorname"
                                setValid={(valid) => { setValid('first_name', valid) }}
                                checkValidity={checkValidity}
                                {...register('first_name')}
                            />
                        </div>
                    </div>
                    <div className="col6">
                        <div>
                            <TextInput
                                required
                                initValue={address['last_name']}
                                type="text"
                                label="Nachname"
                                setValid={(valid) => { setValid('last_name', valid) }}
                                checkValidity={checkValidity}
                                {...register('last_name')}
                            />
                        </div>
                    </div>
                </div>
                <div className="container fluid" style={{ padding: 0 }}>
                    <div className="col12">
                        <div>
                            <TextInput
                                required
                                initValue={address['address_1']}
                                type="text"
                                label="Straße"
                                setValid={(valid) => { setValid('address_1', valid) }}
                                checkValidity={checkValidity}
                                {...register('address_1')}
                            />
                        </div>
                    </div>
                </div>
                <div className="container fluid" style={{ padding: 0 }}>
                    <div className="col12">
                        <div>
                            <TextInput
                                initValue={address['address_2']}
                                type="text"
                                label="Adresszusatz (optional)"
                                checkValidity={checkValidity}
                                {...register('address_2')}
                            />
                        </div>
                    </div>
                </div>
                <div className="container fluid" style={{ padding: 0 }}>
                    <div className="col6">
                        <div>
                            <TextInput
                                required
                                initValue={address['postcode']}
                                type="text"
                                label="PLZ"
                                setValid={(valid) => { setValid('postcode', valid) }}
                                checkValidity={checkValidity}
                                {...register('postcode')}
                            />
                        </div>
                    </div>
                    <div className="col6">
                        <div>
                            <TextInput
                                required
                                initValue={address['city']}
                                type="text"
                                label="Ort / Stadt"
                                setValid={(valid) => { setValid('city', valid) }}
                                checkValidity={checkValidity}
                                {...register('city')}
                            />
                        </div>
                    </div>
                </div>
                <div className="container fluid" style={{ padding: 0 }}>
                    <div className="col12">
                        <div>
                            <TextInput
                                initValue={address['phone']}
                                type="phone"
                                label="Telefonnummer"
                                checkValidity={checkValidity}
                                {...register('phone')}
                            />
                        </div>
                    </div>
                </div>
                <div className="container fluid" style={{ padding: 0 }}>
                    <div className="col12">
                        <div>
                            <TextInput
                                required
                                initValue={address['email']}
                                type="email"
                                label="E-Mail"
                                setValid={(valid) => { setValid('email', valid) }}
                                checkValidity={checkValidity}
                                {...register('email')}
                            />

                            {/* {
                                registerData.withRegister && <TextInput
                                    required
                                    initValue={registerData.password}
                                    type="password"
                                    label="Passwort"
                                    {...register('password')}
                                />
                            } */}
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="col2"></div>
                    <div className="col5">
                        <div>
                            {loggedIn && <Button
                                title="Schritt zurück"
                                type="secondary"
                                destination="/kasse/login"
                                additionalStyle={{ width: '100%' }}
                            />}
                        </div>
                    </div>
                    <div className="col5">
                        <div>
                            <Button
                                title="Weiter"
                                type="primary"
                                action={nextPage}
                                additionalStyle={{ width: '100%' }}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <LoadingSpinner active={loading} type="page" />

        </Layout>
    )
}

export default Billing;
