import { Link } from "@reach/router";

import React, { useEffect, useState } from "react"
import isLoggedIn from "../../../../services/auth";
import * as styles from './style.module.scss';

const CheckoutNavigation = ({ path }) => {

    const [loggedIn, setLoggedIn] = useState();

    const pages = {
        '/login': "Login",
        '/billing': "Rechnung",
        '/shipping': "Versand",
        '/payment': "Zahlung"
    };

    let currentKey = 0;

    Object.keys(pages).forEach((page, key) => {
        if (page === path) currentKey = key;
        console.log("currentKey", page, path)
    })


    useEffect(() => {
        isLoggedIn().then((res) => {

            setLoggedIn(res);

        })
    }, [])


    return (
        <ul className={styles.container}>
            {
                Object.keys(pages).map((_path, key) => {

                    if(loggedIn && _path === '/login') return;

                    return <li className={`${path == _path ? styles.current : null} ${currentKey >= key ? styles.selected : null}`}>
                        <Link to={`/kasse${_path}`}>{pages[_path]}</Link>
                    </li>
                })
            }
            <div className={`${styles.line} ${!loggedIn ? styles.withLogin : null}`}>
                <div className={`${styles.progress} ${styles[path.replace('/', '')]}`}></div>
            </div>
        </ul>
    )
}

export default CheckoutNavigation;
