import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react"
import { CART_IDENTIFYER, getCartItems, removeFromCart } from "../../../functions/cart";
import { floatToString } from "../../../functions/money";
import * as styles from "./styles.module.scss"
import { useDispatch, useSelector } from 'react-redux'

import Button from '../Button';
import CrossIcon from '../../../images/Icons/cross.svg'
import { addToFavourite } from "../../../functions/favourite";

const SideCart = ({ state, open, close }) => {

    const [showContainer, setShowContainer] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [sum, setSum] = useState(0.0);

    const dispatch = useDispatch();
    const cartItems = useSelector(state => state?.cart);


    useEffect(() => {

        if (state) { // IF SHOULD OPEN

            setEnabled(true);

            setTimeout(() => {
                setShowOverlay(true)
            }, 100)

            setTimeout(() => {
                setShowContainer(true)
            }, 300)

        } else {
            setShowContainer(false)

            setTimeout(() => {
                setShowOverlay(false)
            }, 100)

            setTimeout(() => {
                setEnabled(false);
            }, 500)
        }


    }, [state]);

    useEffect(() => {

        let thisSum = 0.0;

        cartItems.forEach(item => {
            thisSum = thisSum + (item.price * item.quantity);
        })

        setSum(thisSum);
    }, [cartItems]);

    if (!enabled) {
        return null;
    }

    return (
        <>
            <div
                className={`${styles.overlay} ${showOverlay ? styles.show : null}`}
                onClick={close}
            ></div>
            <div
                className={`${styles.container} ${showContainer ? styles.show : null}`}
            >
                <h2 style={{ marginLeft: 30, marginTop: 20, marginBottom: 20 }}>Warenkorb</h2>

                <div className={styles.closeButton}
                    onClick={close}
                ></div>

                {cartItems && cartItems.length > 0 && <div className={styles.inner}>
                    {
                        cartItems.map(item => {

                            let price = item.price;

                            price = price * item.quantity;

                            return <div className={styles.item}>
                                <div className={styles.itemContent}>
                                    <div className={styles.image} style={{ backgroundImage: `url(${item.image})` }}></div>
                                    <div className={styles.content}>
                                        <h5 className={styles.title} style={{marginBottom: 0}}>{item.title}</h5>
                                        {item.detail !== undefined && item.detail !== '' ? <p style={{marginTop: 3}}>{item.detail}</p> : <></>}
                                        {item.additionalData !== undefined && item.additionalData.textField !== undefined && item.additionalData.textField !== '' ? <p style={{marginTop: 3}}>Text: {item.additionalData.textField}</p> : <></>}
                                        <div className={styles.details} style={{marginTop: 10}}>
                                            <div className={styles.quantity}>{item.quantity}X</div>
                                            <div className={styles.price}>€ {floatToString(item.price)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.hr}></div>
                                <div className={styles.itemContent} style={{ justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20 }}>
                                    <div className={styles.action} onClick={() => removeFromCart(item.id, dispatch)}>entfernen</div>
                                    <div className={styles.action} onClick={() => {
                                        addToFavourite({
                                            id: item.id,
                                            price: item.price,
                                            title: item.title,
                                            detail: item.detail,
                                            image: item.image,
                                            uri: item.uri
                                        }, dispatch)
                                    }}>Zu Favoriten hinzufügen</div>
                                </div>
                            </div>
                        })
                    }
                </div>
                }
                {
                    cartItems.length > 0 ?
                        <>
                            <div className={styles.sum}><div>Zwischensumme: </div><div>€ {floatToString(sum)}</div></div>

                            <Button
                                additionalStyle={{ width: 'calc(100% - 0px)', marginBottom: 10 }}
                                type="primary"
                                title="Zur Kasse"
                                destination="/kasse"
                            />
                            <Button
                                additionalStyle={{ width: 'calc(100% - 0px)' }}
                                type="secondary"
                                title="Zum Warenkorb"
                                destination="/warenkorb"
                            />

                        </>

                        :

                        <>
                            <h3 style={{ padding: 20 }}>Keine Produkte im Warenkorb</h3>

                            <Button
                                type="primary"
                                title="Zu den Produkten"
                                destination="/shop"
                            />

                        </>
                }

            </div>
        </>
    )
}

export default SideCart