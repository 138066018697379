// extracted by mini-css-extract-plugin
export var action = "styles-module--action--VSHGz";
export var closeButton = "styles-module--closeButton--7qhzd";
export var container = "styles-module--container--dVL--";
export var content = "styles-module--content--XNU4P";
export var details = "styles-module--details--1H9VS";
export var hr = "styles-module--hr--okng0";
export var image = "styles-module--image--wz67n";
export var inner = "styles-module--inner--8We0I";
export var item = "styles-module--item--VmnhA";
export var itemContent = "styles-module--itemContent--bM9WT";
export var overlay = "styles-module--overlay--Ky8IU";
export var price = "styles-module--price--ELC76";
export var show = "styles-module--show--AAM+W";
export var small = "styles-module--small--G0tp6";
export var sum = "styles-module--sum--a-OSK";
export var title = "styles-module--title--MyhAt";