import React, { useEffect, useState } from "react"
import CheckoutNavigation from "./Navigation"
import SideCart from '../../../components/elements/SideCart'
import * as sidecarStyles from '../../../components/elements/SideCart/styles.module.scss'
import * as headerStyles from '../../../scss/header.module.scss'
import Logo from "../../../images/logo.png"
import ThirdContainerSidebar from '../../../components/elements/ThirdContainerSidebar'
import Link from "../../../components/elements/Link";

import { useDispatch, useSelector } from "react-redux";
import { floatToString } from "../../../functions/money";

import { API_BASE } from "../../../spectory-config"
import { storeFormDataAction, storeShippingTotal } from "../../../store/actions"
import { checkLoggedIn, getUserToken } from "../../../services/auth"
import updateCart from "../../../functions/updateCart"
import LoadingSpinner from "../../../components/elements/LoadingSpinner"
const Layout = (props) => {

    const [loading, setLoading] = useState(false);

    const cartItems = useSelector(state => state?.cart);
    const shippingData = useSelector(state => state?.checkout.shipping);
    const shippingCheck = useSelector(state => state?.checkout.shipping.shippingCheck);
    const checkoutData = useSelector(state => state?.checkout.additionalData)



    const dispatch = useDispatch();

    useEffect(() => {
        refreshCart();
        console.log("checkoutData", checkoutData);
    }, [shippingData?.shippingType, shippingCheck, checkoutData?.couponCode]);

    const refreshCart = () => {

        setLoading(true);

        // let items = [];
        // cartItems.forEach(item => {
        //     items.push({
        //         id: item.id,
        //         quantity: item.quantity,
        //         variation_id: item?.variationId || null
        //     });
        // });

        let data = {
            use_shipping: shippingData?.shippingType !== 'pickup',
            cart: cartItems,
        }

        if (
            shippingData.address_1 !== '' &&
            shippingData.postcode !== '' &&
            shippingData.city !== ''
        ) {
            data.shipping_destination = {
                address: shippingData.address_1,
                postcode: shippingData.postcode,
                city: shippingData.city,
            }
        }


        if (checkLoggedIn()) {
            data.token = getUserToken();
        }

        data.use_discount = checkoutData?.useCustomerCardDiscount;

        if (checkoutData.couponCode !== undefined && checkoutData.couponCode !== '') {
            data.coupon = checkoutData.couponCode.replace('-', '');
        }


        console.log("DATA", data);

        updateCart(data).then(res => {

            let additionalData = checkoutData;

            additionalData.sumNet = res.subtotal;
            additionalData.sum = res.total;
            additionalData.tax = res.subtotal_tax;

            additionalData.useCustomerCardDiscount = checkoutData?.useCustomerCardDiscount;

            additionalData.couponTotal = res.coupon_total;
            additionalData.couponRest = res.coupon_rest;

            additionalData.customerCardDiscount = res.customer_card_discount;
            additionalData.customerCardDiscountUsed = res.customer_card_discount_used;

            if (res.shipping_costs !== undefined) {
                additionalData.shipping = res.shipping_costs;
            }

            dispatch(storeFormDataAction({ additionalData: additionalData }));

            setLoading(false);
        })

    }


    const renderSidebar = <>
        <div
            className={`${sidecarStyles.inlineContainer}`}
            style={{ marginTop: 30 }}
        >
            <div className={sidecarStyles.inner}>

                <Link to={"/"} className={headerStyles.logoLink}>
                    <img src={Logo} className={headerStyles.logo} />
                </Link>

                <h2 style={{ marginTop: 35, marginBottom: 30 }}>Warenkorb</h2>


                {
                    cartItems && cartItems.map(item => {

                        let price = item.price;

                        price = price * item.quantity;

                        return <div className={sidecarStyles.item}>
                            <div className={sidecarStyles.itemContent}>
                                <div className={sidecarStyles.image} style={{ backgroundImage: `url(${item.image})` }}></div>
                                <div className={sidecarStyles.content}>
                                    <h5 className={sidecarStyles.title} style={{ fontSize: 16 }}>{item.title}</h5>
                                    {item.detail !== undefined && item.detail !== '' ? <p style={{ marginTop: 3 }}>{item.detail}</p> : <></>}
                                    {item.additionalData !== undefined && item.additionalData.textField !== undefined && item.additionalData.textField !== '' ? <p style={{ marginTop: 3 }}>Text: {item.additionalData.textField}</p> : <></>}
                                    <div className={sidecarStyles.details}>
                                        <div className={sidecarStyles.quantity}>{item.quantity}X</div>
                                        <div className={sidecarStyles.price}>€ {floatToString(item.price)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={sidecarStyles.hr}></div>
                        </div>
                    })
                }
            </div>

            <div className={sidecarStyles.sum}><div>Zwischensumme: </div><div>€ {floatToString(checkoutData?.sumNet)}</div></div>
            <div className={`${sidecarStyles.sum} ${sidecarStyles.small}`}><div>davon MwSt.: </div><div>€ {floatToString(checkoutData?.tax)}</div></div>

            {checkoutData?.shipping !== undefined && checkoutData.shipping.price !== undefined && checkoutData.shipping.price !== null &&
                <div className={sidecarStyles.sum}><div>Versand: </div><div>€ {floatToString(checkoutData.shipping.price)}</div></div>
            }

            {checkoutData?.couponTotal > 0 && <div className={sidecarStyles.sum}><div>Gutschein: </div><div>- € {floatToString(checkoutData?.couponTotal)}</div></div>}
            {checkoutData?.customerCardDiscountUsed > 0 && <div className={sidecarStyles.sum}><div>Guthaben: </div><div>- € {floatToString(checkoutData?.customerCardDiscountUsed)}</div></div>}





            <div className={`${sidecarStyles.sum} ${sidecarStyles.hr}`}><div>Summe: </div><div>€ {floatToString(checkoutData?.sum)}</div></div>
            <LoadingSpinner active={loading} type="wholeContainerTransparent" />
        </div>
    </>;

    return (
        <ThirdContainerSidebar
            sideBarContent={renderSidebar}
            mobileReverse={true}
        >
            <div className="container" style={{ marginTop: 100 }}>
                <div className="col12">
                    <div>

                        <CheckoutNavigation
                            path={props.path}
                        />
                        {props.children}
                        <div style={{ height: 100, width: '100%' }}></div>

                    </div>
                </div>
            </div>
        </ThirdContainerSidebar>
    )
}

export default Layout
